<template>
  <div class="login-page d-flex align-items-center justify-content-center vh-100">
    <div class="login-container shadow-sm">

      <div class="logo mb-4 text-center">
        <h1 class="text-primary fw-bold">{{ $store.state.app.name }}</h1>
        <p class="text-muted">Software para locação de equipamentos</p>
        <hr/>
        <p class="text-dark fw-bold">Acesse sua conta</p>

      </div>

      <div>
        <form @submit.prevent="handleLogin">

          <div class="form-group mb-3 position-relative">
            <label for="email" class="form-label text-muted">Email</label>
            <div class="input-group">
              <span class="input-group-text bg-light text-muted">
                <i class="fas fa-envelope"></i>
              </span>
              <input
                v-model="email"
                type="email"
                id="email"
                class="form-control"
                placeholder="Digite seu email"
                required
              />
            </div>
          </div>

          <div class="form-group mb-3 position-relative">
            <label for="password" class="form-label text-muted">Senha</label>
            <div class="input-group">
              <span class="input-group-text bg-light text-muted">
                <i class="fas fa-lock"></i>
              </span>
              <input
                v-model="password"
                type="password"
                id="password"
                class="form-control"
                placeholder="Digite sua senha"
                required
              />
            </div>
          </div>

          <div class="form-check mb-3">
            <input
              type="checkbox"
              id="rememberMe"
              v-model="rememberMe"
              class="form-check-input"
            />
            <label for="rememberMe" class="form-check-label text-muted">
              Continuar conectado
            </label>
          </div>

          <div class="d-grid">
            <button
              v-if="!loading"
              type="submit"
              class="btn btn-primary btn-block d-flex align-items-center justify-content-center"
              :disabled="loading"
            >
              <span> Entrar </span>
            </button>
            <button
              v-if="loading"
              class="btn btn-primary btn-block d-flex align-items-center justify-content-center"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm text-white"
                aria-hidden="true"
              ></span>
              <span class="ms-2">Entrando...</span>
            </button>
          </div>
        </form>

        <div class="mt-4 text-center">
          <a href="javascript:void(0)" @click="recuperarSenha" class="text-muted">
            Esqueceu sua senha?
          </a>
        </div>

        <transition name="fade">
          <div
            v-if="errorMessage"
            class="alert alert-danger mt-3 text-center"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>


<script>
import MessageBox from "@/shared/sweetAlert2";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      errorMessage: "",
      loading: false,
    };
  },
  mounted() {
    document.title = `Login | ${this.$store.state.app.name}`;

    const savedEmail = localStorage.getItem("email");
    if (savedEmail) {
      this.email = savedEmail;
      this.rememberMe = true;
    }
  },

  beforeUnmount() {
    document.body.classList.add('sidebar-mini');
    document.body.classList.remove('login-page');
  },

  methods: {
    ...mapActions(["efetuarLogin"]),

    async handleLogin() {
      this.loading = true;
      this.errorMessage = "";

      try {
        await this.efetuarLogin({
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        });

        this.loading = false;
        this.$router.push("/inicio");
      } catch (error) {
        this.loading = false;

        if (typeof error === "string") {
          this.errorMessage = error;
        } else if (error.response && error.response.data) {
          this.errorMessage = error.response.data;
        } else if (!error.response) {
          this.errorMessage =
            "Não foi possível conectar ao servidor. Verifique sua conexão com a internet.";
        } else {
          this.errorMessage =
            "Ocorreu um erro ao efetuar o login. Por favor, tente novamente mais tarde.";
        }

        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },

    async recuperarSenha() {
      if (!this.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        MessageBox.toastError("Por favor, informe um email válido para continuar.");
        return;
      }

      try {
        const response = await this.$store.dispatch("verificarUsuario", this.email);

        if (response?.status === 200) {
          MessageBox.toastSuccess("E-mail para redefinição enviado com sucesso.");
        } else if (response?.status === 404) {
          MessageBox.toastError(response?.data || "Usuário não encontrado.");
        }
      } catch (error) {
        MessageBox.toastError(error.message || "Erro ao localizar Usuário.");
      }
    },
  },
};
</script>


<style scoped>
/* Fundo da página */
.login-page {
  background-color: #f8f9fa;
  font-family: "Roboto", sans-serif;
}

/* Contêiner de login */
.login-container {
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
}

/* Logo */
.logo h1 {
  font-size: 24px;
}

.logo p {
  font-size: 14px;
  color: #6c757d;
}

/* Inputs com ícones */
.input-group {
  position: relative;
}

.input-group-text {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-right: none;
  color: #6c757d;
}

.form-control {
  border: 1px solid #ced4da;
  border-left: none;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: none;
}

/* Botão Entrar */
.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.6rem 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif !important;
  font-weight: 550;
}

.btn-primary i {
  font-size: 1rem;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:disabled {
  background-color: #6c757d;
}

/* Links */
a {
  font-size: 14px;
  color: #6c757d;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Mensagem de erro */
.alert {
  background-color: #e3342f;
  border: none;
  color: white;
}
</style>

