import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '@/services/auth.js'
import LoginView from '../views/LoginView.vue';
import MainLayout from '@/layouts/MainLayout.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path : '/',
    component: MainLayout,
    children : [
      {
        path: '',
        redirect: '/login',
      },
      {
        path: 'inicio',
        name: 'inicio',
        component: () => import('../views/InicioView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'cliente-cadastro/:id?',
        name: 'cliente-cadastro',
        component: () => import('../views/ClienteCadastroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'cliente-listagem',
        name: 'cliente-listagem',
        component: () => import('../views/ClienteListagemView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'empresa-cadastro',
        name: 'empresa-cadastro',
        component: () => import('../views/EmpresaCadastroView.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'motorista-cadastro/:id?',
        name: 'motorista-cadastro',
        component: () => import('../views/MotoristaCadastroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'motorista-listagem',
        name: 'motorista-listagem',
        component: () => import('../views/MotoristaListagemView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'equipamento-cadastro/:id?',
        name: 'equipamento-cadastro',
        component: () => import('../views/EquipamentoCadastroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'equipamento-listagem',
        name: 'equipamento-listagem',
        component: () => import('../views/EquipamentoListagemView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'veiculo-cadastro/:id?',
        name: 'veiculo-cadastro',
        component: () => import('../views/VeiculoCadastroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'veiculo-listagem',
        name: 'veiculo-listagem',
        component: () => import('../views/VeiculoListagemView.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'locacao-cadastro/:id?',
        name: 'locacao-cadastro',
        component: () => import('../views/LocacaoCadastroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'locacao-listagem',
        name: 'locacao-listagem',
        component: () => import('../views/LocacaoListagemView.vue'),
        meta: { requiresAuth: true }
      },
      
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
