<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-user"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">

          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" @click.prevent="handleLogout">
            <i class="fas fa-sign-out-alt mr-2"></i> Log Out
          </a>
        </div>
      </li>

    </ul>
  </nav>
</template>

<script>
import { logout } from '@/services/auth.js';

export default {
  methods: {
    handleLogout() {
      logout();
      this.$router.push({ name: 'login' });

    }
  }
}
</script>