import { reactive } from "vue";

export default {
  install(app) {
    // Cria um estado reativo para isMobile
    const deviceState = reactive({
      isMobile: window.innerWidth <= 768,
    });

    const detectMobile = () => {
      deviceState.isMobile = window.innerWidth <= 768;
    };

    // Adiciona o listener para o evento de redimensionamento
    window.addEventListener("resize", detectMobile);

    // Faz o estado disponível globalmente
    app.config.globalProperties.$device = deviceState;

    // Remove o listener ao desmontar o aplicativo
    app.mixin({
      beforeUnmount() {
        window.removeEventListener("resize", detectMobile);
      },
    });
  },
};
