import Swal from "sweetalert2";

const MessageBox = {
  success(message, title = "Sucesso") {
    Swal.fire({
      position: "top",
      icon: "success",
      title: title,
      text: message,
      showConfirmButton: false,
      timer: 2000,
    });
  },

  error(message, title = "Erro") {
    Swal.fire({
      position: "top",
      icon: "error",
      title: title,
      text: message,
      showConfirmButton: true,
    });
  },

  confirm(message, title = "Tem certeza?") {
    return Swal.fire({
      title: title,
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });
  },

  info(message, title = "Informação") {
    Swal.fire({
      position: "top",
      icon: "info",
      title: title,
      text: message,
      showConfirmButton: true,
    });
  },

  // Toasts
  toastSuccess(message) {
    const Toast = Swal.mixin({
      toast: true,
      position: window.innerWidth <= 768 ? "top" : "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    Toast.fire({
      icon: "success",
      title: message,
    });
  },

  toastError(message) {
    const Toast = Swal.mixin({
      toast: true,
      position: window.innerWidth <= 768 ? "top" : "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    Toast.fire({
      icon: "error",
      title: message,
    });
  },

  toastInfo(message) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    Toast.fire({
      icon: "info",
      title: message,
    });
  }
};

export default MessageBox;
