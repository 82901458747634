<template>
  <div class="wrapper">
    <app-navbar />
    <app-sidebar />
    <div class="content-wrapper">
      <router-view/>
    </div>
    
    <footer class="main-footer text-center">
      <div class="float-right d-none d-sm-block"><b>Version</b> Beta</div>
      <strong
        >Copyright &copy; 2025
        <a href="/">LocaMais</a>.</strong
      >
      All rights reserved.
    </footer>
    <aside class="control-sidebar control-sidebar-dark"></aside>
  </div>
</template>

<script>
import AppNavbar from "./inc/AppNavbar.vue";
import AppSidebar from "./inc/AppSidebar.vue";
export default {
  components: {
    AppNavbar,
    AppSidebar
  }
};
</script>

<style>
  .min-vh-100 {
      min-height: 65vh !important;
  }
</style>