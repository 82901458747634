<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link text-center">
      <router-link to="/inicio" class="brand-text font-weight-light ml-2" style="text-decoration: none; color: inherit;">
        <strong>{{ $store.state.app.name }}</strong>
      </router-link>
    </a>

    <div class="sidebar mt-2">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
          <a><i class="fas fa-door-open mr-2"></i> Olá <strong>{{ this.emailPrefix }}</strong></a>
        </div>
      </div>

      <div class="form-inline">
        <div class="input-group" data-widget="sidebar-search">
          <input
            class="form-control form-control-sidebar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>

      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <app-nav-item icon="fas fa-home" link="/inicio" :active="isActive('/inicio')" @click="toggleSidebar">
            Início
          </app-nav-item>

          <!-- Menu Cadastro -->
          <li class="nav-item has-treeview" :class="{ 'menu-open': isCadastroOpen }">
            <a href="#" class="nav-link" :class="{ 'bg-primary': isAnyCadastroActive }">
              <i class="nav-icon fas fa-edit"></i>
              <p>
                Cadastros
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-show="isCadastroOpen">
              <app-nav-item icon="fas fa-user" link="/cliente-listagem" :active="isActive('/cliente-listagem')" @click="toggleSidebar">
                Clientes
              </app-nav-item>
              <app-nav-item icon="fas fa-building" link="/empresa-cadastro" :active="isActive('/empresa-cadastro')" @click="toggleSidebar">
                Empresa
              </app-nav-item>
              <app-nav-item icon="fas fa-box-open" link="/equipamento-listagem" :active="isActive('/equipamento-listagem')" @click="toggleSidebar">
                Equipamentos
              </app-nav-item>
              <app-nav-item icon="fas fa-user-tie" link="/motorista-listagem" :active="isActive('/motorista-listagem')" @click="toggleSidebar">
                Motoristas
              </app-nav-item>
              <app-nav-item icon="fas fa-truck" link="/veiculo-listagem" :active="isActive('/veiculo-listagem')" @click="toggleSidebar">
                Veículos
              </app-nav-item>
            </ul>
          </li>
          <!-- Fim do Menu Cadastro -->

          <app-nav-item icon="fas fa-handshake" link="/locacao-listagem" :active="isActive('/locacao-listagem')" @click="toggleSidebar">
            Locações
          </app-nav-item>

           <!-- Adicione o @click aqui para abrir o Guia Rápido -->
           <app-nav-item icon="fas fa-book" link="#" @click="abrirGuiaRapido">
            Guia Rápido
          </app-nav-item>

        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import AppNavItem from "./AppNavItem.vue"

export default {
  components: {
    AppNavItem
  },
  data() {
    return {
      isCadastroOpen: true
    };
  },
  methods: {
    toggleCadastro() {
      this.isCadastroOpen = !this.isCadastroOpen;
    },
    isActive(route) {
      return this.$route.path === route;
    },
    abrirGuiaRapido() {
      const guiaPath = '/Manual do Usuário - LocaMais ERP.pdf';
      window.open(guiaPath, '_blank');
    },
    toggleSidebar() {
      if (this.$device.isMobile) {
        const pushMenuButton = document.querySelector('[data-widget="pushmenu"]');
        if (pushMenuButton) {
          pushMenuButton.click();
        }
      }
    },
  },
  computed: {
    emailPrefix() {
      const email = this.$store.state.usuario.nome;
      if (email) {
        return email.substring(0, email.indexOf('@'));
      }
      return 'Usuário';
    },
    isAnyCadastroActive() {
      return (
        this.isActive('/cliente-listagem') ||
        this.isActive('/veiculo-listagem') ||
        this.isActive('/motorista-listagem') ||
        this.isActive('/equipamento-listagem')||
        this.isActive('/cliente-cadastro') ||
        this.isActive('/empresa-cadastro') ||
        this.isActive('/veiculo-cadastro') ||
        this.isActive('/motorista-cadastro') ||
        this.isActive('/equipamento-cadastro')
      );
    }
  }
}
</script>
